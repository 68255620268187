@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @font-face {
      font-family: 'TheMunday';
      src: url('/src/assets/fonts/TheMunday.ttf');
      font-weight: 400;
      font-style: normal;
    }
    @font-face {
      font-family: 'HBS';
      src: url('/src/assets/fonts/HBS.ttf');
      font-weight: 400;
      font-style: normal;
    }
    @font-face {
      font-family: 'nesans';
      src: url('/src/assets/fonts/Nesans.ttf');
      font-weight: 400;
      font-style: normal;
    }
    @font-face {
      font-family: 'Avilock';
      src: url('/src/assets/fonts/Avilock.ttf');
      font-weight: 400;
      font-style: normal;
    }
}

html {
  scroll-behavior: smooth;
}